/* eslint-disable react/prop-types */
import React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from '../components/Layout/Layout';

const ThankYouPage = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Thank You | Presidium Health - Home-based Healthcare"
        description="Presidium Health provides home-based care services to patients."
        metaTags={[
          {
            name: 'keywords', 
            content: 'home-based healthcare, value-based healthcare, patient care, telemedicine'
          }
        ]}
      />

      {/* HERO */}
      <div className="hero contact-hero d-flex align-items-lg-center p-relative pt-3 pt-lg-0">
        <Container>
          <Row>
            <Col lg={5}>
              <h1 className="text-primary fw-bold fs-2">Thank you</h1>
              <p>
                Thank you for contacting us!
                <br />A team member will reach out to you very soon
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default ThankYouPage;
